@import "Assets/Styles/colours.scss";

.result-container {
    order: 0;
	flex: 0 1 auto;
	align-self: auto;
	width: 100%;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: none;

    .order-card {
        margin: 0;
        
        .inner-header {
            color: $primary;
            text-align: left;
            padding: 8px 0 0 0;
            background-color: $white;
            display: flex;
            align-items: center;
            align-content: center;
            font-weight: 600;
            align-self: flex-start;
            margin-left: 30px;
    
            .icon {
                border-radius: 50%;
                background-color: $secondary;
                height: 24px;
                width: 24px;
                margin-right: 15px;
    
                svg {
                    height: 13px;
                    width: 13px;
                    padding: 5.5px;
                }
            }

            button {
                border-radius: 30px;
                background-color: $secondary;
                color: $white;
                border: none;
                outline: none;
                transition: 0.2s ease;
                display: flex;
                flex-direction: row;
                align-items: center;
                align-content: flex-start;
                align-self: flex-start;
                padding: 4px 12px;
                margin: 5px;
                cursor: pointer;

                &:hover {
                    background-color: $primary;
                }

                &:focus {
                    outline: none;
                }
            }
        }

        .subheader {
            color: $primary;
            text-align: left;
            padding: 0;
            background-color: $white;
            display: flex;
            align-items: center;
            align-content: center;
            font-weight: 400;
            align-self: flex-start;
            margin-left: 69px;

            .approve-reject {
                display: flex;
                flex-direction: row;
                align-items: center;
                align-self: flex-start;
                margin-left: 10px;

                button {
                    border: none;
                    outline: none;
                    border-radius: 30px;
                    padding: 4px 12px;
                    margin: 5px;
                    color: $white;
                    transition: 0.2s ease;
                    cursor: pointer;

                    &:first-of-type {
                        background-color: rgb(205, 76, 76);
                    }

                    &:last-of-type {
                        background-color: $primary;
                    }

                    &:hover {
                        background-color: $secondary;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }
        }

        .header {
            color: $white;
            text-align: left;
            background-color: $primary;
            display: flex;
            align-items: center;
            align-content: center;
            align-self: flex-start;
            margin: 0;
            font-weight: 700;
            padding: 2px 0;
    
            .icon {
                margin-left: 30px;
                border-radius: 50%;
                background-color: $primary;
                height: 28px;
                width: 28px;
                margin-right: 15px;
    
                svg {
                    height: 19px;
                    width: 19px;
                    padding: 4.5px;
                }
            }

            button {
                border-radius: 30px;
                background-color: $secondary;
                color: $white;
                border: none;
                outline: none;
                transition: 0.2s ease;
                display: flex;
                flex-direction: row;
                align-items: center;
                align-content: flex-start;
                align-self: flex-start;
                padding: 4px 12px;
                margin: 5px;
                cursor: pointer;

                &:hover {
                    background-color: $primary;
                }

                &:focus {
                    outline: none;
                }
            }
        }

        .container {
            padding: 0;
            color: #000;
            background-color: $white;
            height: auto;
            border-bottom: 1px solid rgba(195, 193, 195, 1);

            &:last-of-type {
                border-bottom: none;
            }

            .message-content {
                position: relative;
                display: flex;
                flex-direction: row;
                flex-flow: row;
                align-items: center;
                align-self: center;

                .error {
                    background-color: $white;
                    word-break: break-word;
                    color: #dc3232;
                    padding: 10px 0 10px;
                    text-align: center;
                    font-size: 13px;
                    text-transform: uppercase;
                    font-family: Arial;
                }

                .success {
                    background-color: $white;
                    word-break: break-word;
                    color: #29ab87;
                    padding: 10px 0 10px;
                    text-align: center;
                    font-size: 13px;
                    text-transform: uppercase;
                    font-family: Arial;
                }
            }

            .content {
                position: relative;
                display: flex;
                flex-direction: row;
                flex-flow: row;
                align-items: center;
                align-self: flex-start;
                margin-left: 25px;
                text-wrap: pretty;
    
                &::before {
                    content: "\2022";
                    color: rgba(195, 193, 195, 1);
                    font-weight: 900;
                    font-size: 17px;
                    display: inline-block;
                    margin: 0em 0.4em 0 0;
                }
    
                div {
                    font-size: 13px;
                    word-break: break-word;
                    background-color: $white;
                    padding: 0 5px;
                    width: 100%;
                    border-radius: 0.25rem;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    z-index: 0;

                    button {
                        border: none;
                        outline: none;
                        background-color: $secondary;
                        color: $white;
                        border-radius: 30px;
                        padding: 4px 12px;
                        margin-left: 8px;
                        transition: 0.2s ease;
                        cursor: pointer;

                        &:hover {
                            outline: none;
                            background-color: $primary;
                        }

                        &:focus {
                            outline: none;
                        }
                    }
                }
    
                span {
                    white-space: nowrap;
                    color: $primary;
                    font-weight: 500;
                    font-size: 14px;
                }
            }

            .details-header {
                color: $primary;
                text-align: left;
                padding-top: 4px;
                margin-bottom: 4px;
                align-self: flex-start;
                margin-left: 25px;
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                font-weight: 700;
                font-size: 16px;

                .icon {
                    border-radius: 50%;
                    background-color: $secondary;
                    height: 24px;
                    width: 24px;
                    margin-right: 20px;
                    margin-left: -27px;
        
                    svg {
                        height: 13px;
                        width: 13px;
                        padding: 5.5px;
                    }
                }

                .active, .inactive {
                    display: flex;
                    margin-left: 10px;
                    background-color: $secondary;
                    border-radius: 50%;
                    height: 18px;
                    width: 18px;
                    transition: 0.3s ease;

                    svg {
                        fill: $white;
                        height: 16px;
                        width: 16px;
                        padding: 1px;
                    }

                    &:hover {
                        background-color: $primary;
                    }
                }
    
                .active {
                    transform: rotate(-90deg); 

                    &:hover {
                        transform: rotate(90deg); 
                    }
                }
    
                .inactive {
                    transform: rotate(90deg); 

                    &:hover {
                        transform: rotate(-90deg); 
                    }
                }
            }

            .hidden {
                max-height: 0 !important;
                display: none !important;
            }

            .nested {
                padding-left: 0 !important;
            }

            .staggered-details-container {
                background-color: #e8e8e8c1;

                .content div {
                    background-color: transparent;
                }
            }

            .details-container {
                margin: 0;
                padding: 2px;
                width: 100%;
                border-top: 2px solid $secondary;
                max-height: 9999px;
                transition: 0.3s ease;
                display: block;
                margin-left: 30px;
                padding-left: 100px;
                overflow-x: hidden;
                margin-right: 133px;

                .content {
                    span {
                        font-weight: normal;
                    }
                    div {
                        padding: 2px;
                    }
                    &::before {
                        content:none;
                    }
                }
            }
        }
    }
}
